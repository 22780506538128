@layer tailwind-base, primereact, tailwind-utilities;

@layer tailwind-base {
  @tailwind base;
}

@layer tailwind-utilities {
  @tailwind components;
  @tailwind utilities;
}

:root {
  --dm-primary: rgb(0, 155, 255);
}

.document-viewer {
  .rpv-core__doc-loading {
    background: transparent !important;
    padding: 10px !important;
  }

  .rpv-core__inner-page {
    background-color: transparent;
  }

  .rpv-core__inner-pages.rpv-core__inner-pages--vertical {
    padding: 10px;
  }

  .rpv-core__page-layer::after {
    top: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
  }
  .rpv-core__tooltip-body {
    display: none;
  }

  .rpv-core__popover-body {
    width: 160px;
    translate: -130px 10px !important;
    display: flex;
    align-items: center;
    height: 300px;
    overflow-y: auto;
    overflow-x: hidden;
    border: solid 1px #ddd;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
    padding: 10px !important;
    z-index: 1000 !important;
  }
  .rpv-core__arrow.rpv-core__arrow--bc.rpv-core__popover-body-arrow {
    display: none;
    right: 0;
    position: absolute;
  }
}

/* THEME OVERRIDES */
/* MESSAGE */
/* USE BODY INSTEAD OF #root because modals render outside of root */
body {
  .p-inline-message.p-inline-message-info {
    background: #e9f6ff;
    border: solid #3180ff;
    border-width: 0px;
    color: #3180ff;

    .p-inline-message-icon {
      color: #3180ff;
    }
  }
  .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
    color: #043d75;
  }
  .p-accordion .p-accordion-header:not(.p-disabled) .p-accordion-header-link:focus {
    box-shadow: none !important;
  }

  .pulse-shadow-primary {
    box-shadow: 0 0 0 rgba(0, 155, 255, 0.8);
    animation: pulse 2s infinite;
  }
  /* SELECT BUTTON */
  .p-buttonset .p-button {
    margin: 0;
  }
  .p-buttonset .p-button:first-of-type {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .p-buttonset .p-button:last-of-type {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .p-buttonset .p-button:not(:first-of-type):not(:last-of-type) {
    border-radius: 0;
  }
  .p-buttonset .p-button:not(:last-child) {
    border-right: 0 none;
  }

  /* Fieldset */
  .p-fieldset.p-fieldset-toggleable .p-fieldset-legend a {
    padding: 0.75rem 1rem;
  }
}

.p-dropdown-item-group {
  background-color: white !important;
}

.p-dialog-header {
  padding-block: 0.7rem;
}

.p-blockui-container {
  width: 100% !important;
}

/* 
  .p-dialog-header-icons {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  height: 100% !important;
  display: none !important;
}

.p-dialog-header-icons .p-dialog-header-close {
  border: 2px solid blue !important;
  border-radius: 5px !important;
  padding: 0.5rem !important;
} */

:root {
  --maskbg: rgba(0, 0, 0, 0.3) !important;
}
@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(0, 155, 255, 0.8);
  }
  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(0, 155, 255, 0.5);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(0, 155, 255, 0);
  }
}
@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(0, 155, 255, 0.8);
    box-shadow: 0 0 0 0 rgba(0, 155, 255, 0.4);
  }
  70% {
    -moz-box-shadow: 0 0 0 10px rgba(0, 155, 255, 0.5);
    box-shadow: 0 0 0 10px rgba(0, 155, 255, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(0, 155, 255, 0);
    box-shadow: 0 0 0 0 rgba(0, 155, 255, 0);
  }
}
