.instructionsTable,
.instructionsTable td,
.instructionsTable th {
  border: solid 1px #cccccc66;
  padding: 5px 10px;
}

/* we lost the border at the bottom of the tabs for some reason . This seems to restore it. */
.tab {
  border-bottom: 2px solid #ddd;
}

li.primary {
  position: relative;
  /* overflow: hidden; */
}

/* divider between primary and secondary  */
li.primary:before {
  content: '';
  position: absolute;
  right: 0;
  top: 15px;
  bottom: 15px;
  width: 1px;
  background-color: #ddd;
  border-radius: 0;
  z-index: 10;
}

/* applies the primary label for primary tabs */
.primary :global(.p-tabview-nav-link:before) {
  content: 'Primary';
  position: absolute;
  top: 0px;
  font-size: 12px;
  color: #777;
}

.disabled :global(.p-disabled .p-tabview-nav-link) {
  @apply !text-slate-300;
}

/* In order to prevent the association label from being clipped by a small association tab, we need to set a min-width on the association tab. */
.association {
  min-width: 100px;
}

/* applies the association label for association tabs */
.association :global(.p-tabview-nav-link:before) {
  content: 'Associations';
  position: absolute;
  top: 0;
  font-size: 12px;
  color: #777;
}
